import React, { useState } from 'react';
import './SmartSwitch.css';
import img from '../page/assets/feathertouch.png';
import { useNavigate } from 'react-router-dom';

const SmartSwitch2 = () => {
    const [quantity, setQuantity] = useState(1);
    const basePrice = 3500; // Base price for a single product
    const totalPrice = basePrice * quantity;

 
    const navigate = useNavigate();

    const increaseQuantity = () => setQuantity(quantity + 1);
    const decreaseQuantity = () => quantity > 1 && setQuantity(quantity - 1);

    const handleBuyNowClick = () => {
        const newCartItem = {
            id: Date.now(),
            quantity,
            price: totalPrice,
            img,
            name:"Feathertouch"

        };
        console.log("cart",newCartItem);
        

        // Retrieve existing cart data from localStorage or initialize as empty
        const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
        storedCart.push(newCartItem);

        // Save updated cart back to localStorage
        const res=localStorage.setItem("cart", JSON.stringify(storedCart));
        console.log("res",res);
        


        navigate("/checkout");
    };

    return (
        <div className="smart-switch-container">
            <div className="content">
                <div className="product-info">
                    <h1>Wifi Smart Switch 8 Gang</h1>
                    <p>
                        Seamless Control, Enhanced Comfort<br />
                        All at Your Fingertips. Upgrade to a smart switch<br />
                        that gives you full control over your<br />
                        lighting and appliances with just a tap.
                    </p>

                    <span className="price">Price: ₹ {basePrice}</span>
                    <span className="total-price">Total: ₹ {totalPrice}</span>

                    <div className="quantity-buy-now">
                        <button onClick={decreaseQuantity}>-</button>
                        <span className="quantity">{quantity}</span>
                        <button onClick={increaseQuantity}>+</button>
                        <button className="buy-now" onClick={handleBuyNowClick}>Buy Now</button>
                    </div>
                </div>

                <div className="product-image">
                    <img src={img} alt="Smart Switch" />
                </div>
            </div>
        </div>
    );
};

export default SmartSwitch2;
//<i class="bi bi-x-circle"></i>
