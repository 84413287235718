// Footer.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    // Define state for active link and menu open
    const [activeLink, setActiveLink] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);

    const handleLinkClick = (link) => {
        setActiveLink(link);
        setMenuOpen(false); // Close the menu after clicking on a link
    };
    
    return (
        <footer className="footer">
            <Link className='a' to="/About" onClick={()=> handleLinkClick('about')}>About</Link>
            <Link className='b' to="/terms" onClick={() => handleLinkClick('terms')}>Terms & Condition</Link>
            <Link className='c' to="/Privacy" onClick={() => handleLinkClick('privacy')}>Privacy Policy</Link>
            <Link className='d' to="/Contact" onClick={() => handleLinkClick('contact')}>Contact Us</Link>
        </footer>
    );
};

export default Footer;
