import React from "react";
import { Container } from "react-bootstrap";
import './Privacy.css';

function Privacy() {
    return (
        <div className="privacy-page">
        
            <Container>
                <div className="privacy-content">
                    <h2 className="privacy-title">Privacy Policy:</h2>
                    <p className="privacy-text">
                        At Grafin, we value your privacy and are committed to protecting your personal
                        information. This Privacy Policy explains how we collect, use, and share
                        information when you visit our website and interact with our products and services.
                    </p>

                    <h3 className="privacy-heading">Information we Collect</h3>
                    <p className="privacy-text">
                        <b>Information You Provide:</b> We may collect personal information you voluntarily
                        provide, such as your name, email address, phone number, and shipping address
                        when you make a purchase or create an account.
                    </p>
                    <p className="privacy-text">
                        <b>Device and Usage Information:</b> We may automatically collect information about
                        your device, browsing actions, and patterns when you visit our website. This may
                        include your IP address, browser type, operating system, referring URLs, and
                        information about how you interact with our site.
                    </p>

                    <h3 className="privacy-heading">How We Use Your Information</h3>
                    <p className="privacy-text">
                        <b>Provide and Improve Our Services:</b> We use your information to process your orders,
                        communicate with you, personalize your experience, and improve our products and services.
                    </p>
                    <p className="privacy-text">
                        <b>Marketing and Communication:</b> With your consent, we may send you promotional emails
                        or newsletters about our products, special offers, or other updates. You can opt out of
                        these communications at any time.
                    </p>
                    <p className="privacy-text">
                        <b>Analytics and Research:</b> We may use your information for data analysis, research,
                        and statistical purposes to better understand our customers and improve our offerings.
                    </p>
                    <p className="privacy-text">
                        <b>Legal Compliance:</b> We may use your information to comply with applicable laws,
                        regulations, or legal processes.
                    </p>

                    <h3 className="privacy-heading">Your Choices</h3>
                    <p className="privacy-text">
                        <b>Access and Control:</b> You have the right to access and control your personal
                        information. You can update your preferences, correct inaccuracies, or request
                        deletion of your data by contacting us.
                    </p>
                    <p className="privacy-text">
                        <b>Marketing Communications:</b> You can unsubscribe from our marketing emails by
                        clicking the "unsubscribe" link at the bottom of any email.
                    </p>

                    <h3 className="privacy-heading">Children's Privacy</h3>
                    <p className="privacy-text">
                        Our website is not intended for children under the age of 13. We do not knowingly
                        collect personal information from children.
                    </p>

                    <h3 className="privacy-heading">Changes to This Privacy Policy</h3>
                    <p className="privacy-text">
                        We may update this Privacy Policy from time to time to reflect changes in our
                        practices or for other operational, legal, or regulatory reasons. We will notify
                        you of any material changes by posting the new Privacy Policy on our website.
                    </p>
                </div>
            </Container>
           </div>
    );
}

export default Privacy;
