import React from "react";
import { Container } from "react-bootstrap";
import './Terms.css';

function Terms() {
    return (
        <div className="terms-page">
            <Container>
                <div className="terms-content">
                    <h2 className="terms-title">Terms and Conditions:</h2>
                    <p className="terms-text">
                        Welcome to Grafin website. By accessing or using this Site, you agree to be bound
                        by these Terms and Conditions. If you do not agree to these terms, please do not
                        use this Site.
                    </p>

                    <h3 className="terms-heading">1. Use of the Site</h3>
                    <p className="terms-text">
                        *You may use this Site for lawful purposes only. You agree not to use this Site in
                        any way that violates any applicable federal, state, local, or international law or
                        regulation.
                    </p>
                    <p className="terms-text">
                        *You agree not to engage in any activity that could harm, disable, overburden, or
                        impair the Site or interfere with any other party's use and enjoyment of the Site.
                    </p>

                    <h3 className="terms-heading">2. Intellectual Property</h3>
                    <p className="terms-text">
                        *All content on this Site, including text, graphics, logos, images, videos, audio
                        clips, and software, is the property of Grafin or its content suppliers and is
                        protected by copyright, trademark, and other intellectual property laws.
                    </p>

                    <h3 className="terms-heading">3. Product Information and Pricing</h3>
                    <p className="terms-text">
                    We strive to provide accurate product information and pricing on this Site.
                    However, we do not warrant that product descriptions or other content on this Site
                    is accurate, complete, reliable, current, or error-free.

                    We reserve the right to correct any errors, inaccuracies, or omissions and to
                    change or update information at any time without prior notice.
                    </p>

                    <h3 className="terms-heading">4. Orders and Payment</h3>
                    <p className="terms-text">
                    All orders are subject to acceptance and availability.
                    We reserve the right to refuse or cancel any order for any reason at any time.
                    Payment for all products must be made in full before shipment.
                    </p> 

                    <h3 className="terms-heading">5. Shipping and Delivery</h3>
                    <p className="terms-text">
                    Shipping is included in the price. We deliver within one day
                    throughout Tamil Nadu. Due to rush orders or holidays, delivery may
                    take up to two days. Delivery to the rest of India may take 3 to 5 days.<br/><br/>

                    <b>Returns</b>

                    We want you to be completely satisfied with your purchase. If you're not, simply
                    return your products within 3 days of delivery for a full refund or exchange.
                    See our full Return Policy for details and instructions.
                    </p>

                    <h3 className="terms-heading">6. Disclaimer of Warranties</h3>
                    <p className="terms-text">
                    This site and all information, content, materials, products (including software),
                    and services included on or otherwise made available to you through this site are
                    provided by grafin on an "as is" and "as available" basis, unless otherwise specified in writing.
                    We make no representations or warranties of any kind, express or implied, 
                    as to the operation of this site or the information, content, materials, products 
                    (including software), or services included on or otherwise made available to you 
                    through this site, unless otherwise specified in writing.
                    </p>

                    <h3 className="terms-heading">7. Limitation of Liability</h3>
                    <p className="terms-text">
                    In no event shall grafin or its directors, officers, employees, or agents be 
                    liable for any direct, indirect, punitive, incidental, special, or consequential
                    damages arising out of or in any way connected with the use of this site or with
                    the delay or inability to use this site, or for any information, content, 
                    materials, products (including software), or services obtained through this site,
                    or otherwise arising out of the use of this site.
                    </p>

                    <h3 className="terms-heading">8. Governing Law</h3>
                    <p className="terms-text">
                    These Terms and Conditions shall be governed by and construed in accordance with
                    the laws of [Your State/Province/Country], without regard to its conflict of law
                    provisions.
                    </p>

                    <h3 className="terms-heading">9. Changes to Terms and Conditions</h3>
                    <p className="terms-text">
                        We reserve the right to modify these Terms and Conditions at any time.
                        Your continued use of this Site following any changes constitutes your
                        acceptance of the new Terms and Conditions.
                    </p>
                </div>
            </Container>
        </div>
    );
}

export default Terms;
