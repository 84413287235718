import React, { useState } from "react";
import "./Contact.css";

function ContactUs() {
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        message: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Data:", formData);
    };

    return (
        <div className="contact-container">
            <div className="contact-details">
            <h1>Get In Touch...</h1>
            <p>We’ve sparked growth and meaningful change across various industries, and we’re thrilled to build on your vision. Share a little about yourself, and let’s set things in motion.</p>
                
            </div>
            <div className="contact-form">
                <div className="contact-text">
                <h2>Contact Details</h2>
                <p>Email Address: giga98984866762@gmail.com</p>
                <p>Phone Number: 8807130095</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        name="phone"
                        placeholder="Mobile Number"
                        value={formData.phone}
                        onChange={handleChange}
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <textarea
                        name="message"
                        placeholder="Message"
                        rows="4"
                        value={formData.message}
                        onChange={handleChange}
                    />
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
}

export default ContactUs;
