// import React, { useState } from 'react';
// import './Navbar.css';
// import logoImage from '../page/assets/grafinlogo.png';
// import { FaBars, FaTimes } from 'react-icons/fa';
// import { Link } from 'react-router-dom'; 

// const Navbar = () => {
    
//     const [activeLink, setActiveLink] = useState('home');

//     const [isMenuOpen, setIsMenuOpen] = useState(false);

//     const toggleMenu = () => {
//       setIsMenuOpen(!isMenuOpen);
//     };
  

//     const handleLinkClick = (link) => {
//         setActiveLink(link);
//         setIsMenuOpen(false); 
//     };

//     return (
//         <header className="navbar">
//         <div className="logo">
//           <Link to="/" onClick={() => handleLinkClick('home')}>
//             <img src={logoImage} alt="Logo" />
//           </Link>
//         </div>
  
//         <div className='navlink'>
//           <div className="menu-icon" onClick={toggleMenu}>
//             <span>&#9776;</span>
//           </div>
  
//           <div className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
//             <Link to="/" onClick={() => handleLinkClick('home')}>SmartSwitch</Link>
//             <Link to="/SmartSwitch1" onClick={() => handleLinkClick('fanwithfeather')}>FanwithFeather</Link>
//             <Link to="/SmartSwitch2" onClick={() => handleLinkClick('feathertouch')}>Feathertouch</Link>
//           </div>
//         </div>
//       </header>
//     );
// };

// export default Navbar;

import React, { useState } from "react";
import "./Navbar.css";
import logoImage from "../page/assets/grafinlogo.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [activeLink, setActiveLink] = useState("home"); // Active link state
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setIsMenuOpen(false);
  };

  return (
    <header className="navbar">
      <div className="logo">
        <Link to="/" onClick={() => handleLinkClick("home")}>
          <img src={logoImage} alt="Logo" />
        </Link>
      </div>

      <div className="navlink">
        <div className="menu-icon" onClick={toggleMenu}>
          <span>&#9776;</span>
        </div>

        <div className={`nav-links ${isMenuOpen ? "open" : ""}`}>
          <Link
            to="/"
            onClick={() => handleLinkClick("home")}
            className={activeLink === "home" ? "active" : ""}
          >
            SmartSwitch
          </Link>
          <Link
            to="/SmartSwitch1"
            onClick={() => handleLinkClick("fanwithfeather")}
            className={activeLink === "fanwithfeather" ? "active" : ""}
          >
            FanwithFeather
          </Link>
          <Link
            to="/SmartSwitch2"
            onClick={() => handleLinkClick("feathertouch")}
            className={activeLink === "feathertouch" ? "active" : ""}
          >
            Feathertouch
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
