import { width } from "@fortawesome/free-solid-svg-icons/fa0";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Checkout = () => {
    const [cart, setCart] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
        setCart(storedCart);
    }, []);

    const handleDelete = (id) => {
        const updatedCart = cart.filter((item) => item.id !== id);
        setCart(updatedCart);
        localStorage.setItem("cart", JSON.stringify(updatedCart));
    };

    const handleQuantityChange = (id, increment) => {
        const updatedCart = cart.map((item) => {
            if (item.id === id) {
                const newQuantity = increment ? item.quantity + 1 : item.quantity - 1;
                if (newQuantity > 0) {
                    return {
                        ...item,
                        quantity: newQuantity,
                        price: (item.price / item.quantity) * newQuantity,
                    };
                }
            }
            return item;
        });
        setCart(updatedCart);
        localStorage.setItem("cart", JSON.stringify(updatedCart));
    };

    const subTotal = cart.reduce((acc, item) => acc + item.price, 0);
    const shippingCharge = cart.length > 0 ? 50 : 0;
    const gst = (subTotal * 0.1).toFixed(2);
    const finalTotal = (subTotal + shippingCharge + parseFloat(gst)).toFixed(2);

    return (
        <div style={styles.container}>
            {/* Wrap the product table inside a horizontally scrollable container */}
            <div style={styles.scrollableContainer}>
                <div style={styles.productTable}>
                    <table style={styles.table}>
                        <thead style={styles.tableHeader}>
                            <tr>
                                <th style={styles.tableHeaderCell}>Image</th>
                                <th style={styles.tableHeaderCell}>Product</th>
                                <th style={styles.tableHeaderCell}>Price</th>
                                <th style={styles.tableHeaderCell}>Qty</th>
                                <th style={styles.tableHeaderCell}>Sub Total</th>
                                <th style={styles.tableHeaderCell}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {cart.length > 0 ? (
                                cart.map((item) => (
                                    <tr key={item.id} style={styles.tableRow}>
                                        <td style={styles.tableCell}>
                                            <img
                                                src={item.img}
                                                alt="Product"
                                                style={styles.productImage}
                                            />
                                        </td>
                                        <td style={styles.tableCell}>{item.name}</td>
                                        <td style={styles.tableCell}>
                                            ₹ {(item.price / item.quantity).toFixed(2)}
                                        </td>
                                        <td style={styles.tableCell}>
                                            <div style={styles.quantityControl}>
                                                <button
                                                    style={styles.quantityButton}
                                                    onClick={() =>
                                                        handleQuantityChange(item.id, false)
                                                    }
                                                >
                                                    -
                                                </button>
                                                <span style={styles.quantityText}>
                                                    {item.quantity}
                                                </span>
                                                <button
                                                    style={styles.quantityButton}
                                                    onClick={() =>
                                                        handleQuantityChange(item.id, true)
                                                    }
                                                >
                                                    +
                                                </button>
                                            </div>
                                        </td>
                                        <td style={styles.tableCell}>₹ {item.price.toFixed(2)}</td>
                                        <td style={styles.tableCell}>
                                            <i
                                                className="bi bi-x-circle"
                                                style={styles.deleteIcon}
                                                onClick={() => handleDelete(item.id)}
                                            ></i>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan="6"
                                        style={{
                                            textAlign: "center",
                                            padding: "20px",
                                        }}
                                    >
                                        No items in the cart.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* Rest of the details */}
            <div style={styles.cartDetails}>
                <h3 style={styles.cartDetailsHeading}>Cart Details</h3>
                <table style={styles.detailTable}>
                    <tbody>
                        <tr>
                            <td style={styles.detailLabel}>Sub Total</td>
                            <td style={styles.detailValue}>₹ {subTotal.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td style={styles.detailLabel}>Shipping</td>
                            <td style={styles.detailValue}>
                                Flat rate ₹ {shippingCharge}
                                <br />
                                <span style={styles.shippingFrom}>
                                    Shipping From <br /> Tamilnadu
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td style={styles.detailLabel}>GST</td>
                            <td style={styles.detailValue}>₹ {gst}</td>
                        </tr>
                        <tr>
                            <td style={styles.detailLabel}>Total</td>
                            <td style={styles.detailValue}>₹ {finalTotal}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <button style={styles.checkoutButton} onClick={() => navigate("/")}>
                Checkout
            </button>
        </div>
    );
};

export default Checkout;

const styles = {
    shippingFrom: {
        color: "#a7a7a7", 
        fontSize: "12px", 
        display: "block", 
        marginTop: "5px", 
    },
    container: {
        backgroundColor: "#333",
        color: "#333",
        padding: "30px",
        fontFamily: "'Roboto', sans-serif",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowX:"hidden",
        // width:"80%",
        // padding: "0 auto",
        // margin:"0 auto"
    },
    productTable: {
        // width: "90%",
        minWidth: "800px",
        backgroundColor: "#333",
        border: "1px solid #ddd",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        // overflow: "hidden",
        marginBottom: "20px",
        width:"80%",
        padding: "0 auto",
        margin:"0 auto"
    },
    scrollableContainer: {
        width: "100%",
        overflowX: "auto",
    },
    // productTable: {
    //     minWidth: "800px",
    //     backgroundColor: "#333",
    //     border: "1px solid #ddd",
    //     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    //     marginBottom: "20px",
    // },
    table: {
        width: "100%",
        borderCollapse: "collapse",
    },
    tableHeader: {
        backgroundColor: "#2E2C2C",
    },
    tableHeaderCell: {
        padding: "10px",
        textAlign: "center",
        color: "#FFC107",
        fontWeight: "bold",
        fontSize: "14px",
        borderBottom: "1px solid #ddd",
    },
    tableRow: {
        borderBottom: "1px solid #ddd",
    },
    tableCell: {
        padding: "10px",
        textAlign: "center",
        color: "white",
        borderBottom: "1px solid #ddd",
    },
    productImage: {
        width: "50px",
        height: "50px",
        borderRadius: "5px",
    },
    deleteIcon: {
        cursor: "pointer",
        color: "white",
    },
    cartDetails: {
        maxWidth: "400px",
        width: "100%",
        backgroundColor: "#333",
        border: "1px solid #ddd",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        padding: "10px",
        marginTop:'25px'
    },
    cartDetailsHeading: {
        marginBottom: "10px",
        color: "#FFC107",
    },
    detailTable: {
        width: "100%",
        borderCollapse: "collapse",
    },
    detailLabel: {
        padding: "10px",
        fontSize: "14px",
        fontWeight: "bold",
        color: "white",
        borderBottom: "1px solid #ddd",
        
    },
    detailValue: {
        padding: "10px",
        textAlign: "right",
        fontSize: "14px",
        fontWeight: "bold",
        color: "white",

        borderBottom: "1px solid #ddd",
    },
   
    checkoutButton: {
        marginTop: "20px",
        width: "35%",
        padding: "5px",
        backgroundColor: "#D09B2B",
        color: "black",
        border: "none",
        borderRadius: "5px",
        fontSize: "16px",
        fontWeight: "bold",
        cursor: "pointer",
        textalign:"center"
    },
    quantityControl: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    quantityButton: {
        padding: "5px 10px",
        fontSize: "20px",
        fontWeight: "bold",
        color: "#fff",
        // backgroundColor: "#FFC107",
        border: "none",
        borderRadius: "3px",
        cursor: "pointer",
        margin: "0 5px",
    },
    quantityText: {
        fontSize: "16px",
        fontWeight: "bold",
        color: "white",
    },
    from:{
        color:"#fff",
    }
};


