import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./About.css"; // Import the CSS file
import switchImage from "../page/assets/smartswitch.png"; // Replace with the path to your image
import tick from "../page/assets/tick.png";

function About() {
    return (
        <div className="about-section">
            <Container>
                <Row className="align-items-center">
                    {/* Left Side Image */}
                    <Col md={5} className="about-image-col">
                        <img src={switchImage} alt="Smart Switch" className="about-image" />
                    </Col>

                    {/* Right Side Content */}
                    <Col md={7}>
                        <div className="about-content">
                            <h2 className="about-title">ABOUT US</h2>
                            <p className="about-description">
                                Grafin Electronics has been a leading provider of Smart Switches in Tamil Nadu since 1993.
                                We are committed to offering the lowest prices ever seen in the state, along with exceptional
                                quality and service.
                                <br />
                                We understand that Tamil Nadu residents value both affordability and quality. That's why we offer
                                the best deals on top-of-the-line Smart Switches, delivered straight to your doorstep the very next day.
                                <br />
                                At Grafin Electronics, we go beyond just being a business. We believe in building trust and
                                relationships with our customers. We are a company of our word, committed to prompt service and
                                honest dealings.
                            </p>
                            <h3 className="about-subtitle">Why Choose Our Switch</h3>
                            <ul className="about-features-list">
                                <li className="about-feature-item">
                                    <img src={tick} className="list-img" alt="tick" />
                                    <span>Effortless Control</span>
                                </li>
                                <li className="about-feature-item">
                                    <img src={tick} className="list-img" alt="tick" />
                                    <span>Energy-Saving Technology</span>
                                </li>
                                <li className="about-feature-item">
                                    <img src={tick} className="list-img" alt="tick" />
                                    <span>Sleek, Modern Design</span>
                                </li>
                                <li className="about-feature-item">
                                    <img src={tick} className="list-img" alt="tick" />
                                    <span>Easy Installation</span>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default About;
