
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import SmartSwitch from './page/SmartSwitch';
import Navbar from './page/Navbar';
import Footer from './page/Footer';
import Terms from './page/Terms';
import Privacy from './page/Privacy';
import About from './page/About';
import ContactUs from './page/Contact';
import SmartSwitch1 from './page/SmartSwitch1';
import SmartSwitch2 from './page/SmartSwitch2'
import Checkout from './page/Checkout';
import 'bootstrap-icons/font/bootstrap-icons.css';

function App() {
  return (
    <Router>
    <Navbar />
    <Routes>
      <Route path="/" element={<SmartSwitch />} />
      
      <Route path="/SmartSwitch1" element={<SmartSwitch1/>} />
      <Route path="/SmartSwitch2" element={<SmartSwitch2/>} /> {/* Home page */}
      <Route path="/Checkout" element={<Checkout/>} /> 
      
       {/* Home page */}
      
       {/* Home page */}
      <Route path="/About" element={<About/>} /> {/* About page */}
      <Route path="/Terms" element={<Terms/>}/>
      <Route path="/Privacy" element={<Privacy/>}/>
      <Route path="/Contact" element={<ContactUs />}/>
    </Routes>
    <Footer />
  </Router>
  );
}

export default App;
